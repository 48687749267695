<template>
  <div class="ApplicationUse">
    <div class="contion">
      <div class="right">
        <!-- <div class="title">申请试用</div> -->
        <!-- <el-divider></el-divider> -->
        <div class="steps">
          <div class="st" :class="cursteps > 0 ? 'blue' : ''">
            <span class="index">1</span>
            <span class="font">填写信息</span>
          </div>
          <div class="line" :class="cursteps > 1 ? 'blueline' : ''"></div>
          <div class="st" :class="cursteps > 1 ? 'blue' : ''">
            <span class="index">2</span>
            <span class="font">信息审核</span>
          </div>
          <div class="line" :class="cursteps > 2 ? 'blueline' : ''"></div>
          <div class="st" :class="(cursteps > 2 && !isRejected) ? 'blue' : ''">
            <span class="index">3</span>
            <span class="font">登录试用</span>
          </div>
        </div>
        <div class="form-l">
          <el-form ref="form" label-position="top" :model="form" :rules="rules" label-width="auto" v-if="cursteps == 1"
            class="message-form-1">
            <el-form-item label="企业名称" prop="company_name" required>
              <el-input v-model="form.company_name"></el-input>
            </el-form-item>
            <el-form-item label="企业地址" prop="company_address" required>
              <el-input v-model="form.company_address"></el-input>
            </el-form-item>
            <el-form-item label="企业行业" prop="company_industry" required>
              <el-select v-model="form.company_industry" style="width: 100%;" multiple placeholder="请选企业所属行业">
                <el-option label="生鲜" value="生鲜"></el-option>
                <el-option label="医药批发" value="医药批发"></el-option>
                <el-option label="医药连锁" value="医药连锁"></el-option>
                <el-option label="医疗器械" value="医疗器械"></el-option>
                <el-option label="酒水" value="酒水"></el-option>
                <el-option label="医疗生产" value="医疗生产"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
              <!-- <el-input v-model="form.company_industry"></el-input> -->
            </el-form-item>
            <el-form-item label="联系人姓名" prop="apply_name" required>
              <el-input v-model="form.apply_name"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="mobile" required>
              <el-input v-model="form.mobile"></el-input>
            </el-form-item>
            <el-form-item class="confirm-btn">
              <el-button style="width: 100%; margin-top: 0.8rem; background-color: #0560d6;" type="primary"
                @click="submit">提交</el-button>
            </el-form-item>
          </el-form>
          <div v-else-if="cursteps == 2" class="message-form">
            <div class="iconcheck">
              <img src="../assets/image/applicationUse/register.png">
            </div>
            <div class="span1">已注册待审核</div>
            <div class="span2" style="margin-top: 0.2rem;">申请成功，等待审核结果！</div>
          </div>
          <div v-else-if="cursteps == 3" class="message-form">
            <div v-if="!isRejected">
              <div class="iconcheck">
                <img src="../assets/image/applicationUse/checkPass.png">
              </div>
              <div class="span1">审核通过</div>
              <div class="span2" style="margin-top: 0.2rem;">后台开户通过后，您将通过短信收到登录账号/密钥</div>
            </div>
            <div v-else>
              <div class="iconcheck">
                <img src="../assets/image/applicationUse/checkReject.png">
              </div>
              <div class="span1">审核驳回</div>
              <div class="span2">{{ rejectResin }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
export default {
  components: {
    vueQr
  },
  data () {
    return {
      bound: false, //是否已绑定，默认为否
      isRejected: false,
      currentunionid: null,
      rejectResin: '',
      rules: {
        company_name: [
          { required: true, message: '请输入企业名称', trigger: 'blur' },
          { required: true, message: '请输入企业名称', trigger: 'change' }
        ],
        company_address: [
          { required: true, message: '请输入企业名称', trigger: 'change' },
          { required: true, message: '请输入企业名称', trigger: 'blur' }
        ],
        company_industry: [
          { required: true, message: '请输入企业所属行业', trigger: 'blur' },
          { required: true, message: '请输入企业所属行业', trigger: 'change' }
        ],
        apply_name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
          { required: true, message: '请输入联系人姓名', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '请输入联系人电话', trigger: 'change' },
          { required: true, message: '请输入联系人电话', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' }
        ]
      },
      limitCodeTime: 60,
      codeTime: 60,
      codeTimeFlag: false,
      form: { company_name: '', company_address: '', company_industry: [], apply_name: '', mobile: '' },
      radio: '',
      cursteps: 1,
      activeName: 'first',
      validateCode: '',
      phone: '',
      formData: {
        phone: '',
        smsVerify: ''
      },
      listData: [
        {
          text: 'A：立即沟通',
          label: '1'
        },
        {
          text: 'B：今天18:00前联系我',
          label: '2'
        },
        {
          text: 'C：明天联系我:00前联系我',
          label: '3'
        },
      ]
    }
  },
  methods: {
    checkTelephone (telephone) {
      var reg = /^[1][3,4,5,7,8][0-9]{9}$/
      if (!reg.test(telephone)) {
        return false
      } else {
        return true
      }
    },
    async submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.form))
          params.company_industry = params.company_industry.toString()
          if (!this.bound) {
            // 未绑定
            params.unionid = this.currentunionid
          }
          const res = await this.http.service({
            url: '/tdp/script/Invoke/saveApplyInfo',
            data: { params: params }
          })
          if (res.code !== 200) {
            this.$message.warning(res.message)
            return
          } else {
            localStorage.setItem('curphone', this.form.mobile)
            this.phone = this.form.mobile
            this.$message.success('已提交申请!')
            this.cursteps = 2
            this.selectstaus()
          }
        } else {
          return false
        }
      })
    },
    async selectstaus () {
      // 在组件中设置 localStorage
      if (this.form.mobile) {
        localStorage.setItem('curphone', this.form.mobile)
      }
      const res = await this.http.service({
        url: '/tdp/script/Invoke/ApplyUserSelect',
        data: {
          params: {
            mobile: this.form.mobile
          }
        }
      })
      this.isRejected = false
      if (res.code !== 200) {
        this.$message.error(res.message)
        return
      } else {
        switch (res.data[0].apply_status) {
          case '0':
            this.cursteps = 2
            break
          case '1':
            this.cursteps = 3 //已审核
            break
          case '-1':
            this.cursteps = 3 //驳回
            // this.$message.error('审核被驳回!')
            this.isRejected = true
            this.rejectResin = res.data[0].remark
            break
          case '2': // 已注册
            this.cursteps = 3
            // this.$message.success('该信息已注册!')
            break
          default:
        }
      }
    },
    async getCode () {
      if (this.codeTimeFlag) return
      if (!this.formData.phone) {
        this.$message.warning('联系电话不能为空')
        return
      }
      if (!this.checkTelephone(this.formData.phone)) {
        this.$message.warning('联系电话格式不正确')
        return
      }
      const res = await this.http.service({
        url: '/tdp/msg/smsVerify/send',
        data: {
          bizType: 'resolvePlan',
          "templateId": 1,
          verifyLength: 4,
          "to": this.formData.phone,
          verifyValidSecond: this.limitCodeTime,
          verifyResendSecond: this.limitCodeTime,
        }
      })
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      this.$message.success('验证码发送成功')
      //   开始计时
      this.startCodeTime()
      this.validateCode = res.data
    },
    startCodeTime () {
      this.timer = setInterval(() => {
        this.codeTime = this.codeTime - 1
        if (this.codeTime > 0) {
          this.codeTimeFlag = true
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.codeTime = this.limitCodeTime
          this.codeTimeFlag = false
          this.validateCode = ''
        }
      }, 1000)
    },
    async validform () {
      if (!this.formData.phone) {
        this.$message.warning('联系电话不能为空')
        return
      }
      if (!this.checkTelephone(this.formData.phone)) {
        this.$message.warning('联系电话格式不正确')
        return
      }
      const res = await this.http.service({
        url: '/tdp/msg/smsVerify/valid',
        data: {
          requestId: this.validateCode,
          smsVerify: this.formData.smsVerify
        }
      })
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      // this.$message.success('登录成功!')
    },
    GetQueryString (name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i")
      var r = window.location.search.substr(1).match(reg) //获取url中"?"符后的字符串并正则匹配
      var context = ""
      if (r != null)
        context = r[2]
      reg = null
      r = null
      return context == null || context == "" || context == "undefined" ? "" : context
    },
    async handleCode () {
      const _this = this
      const wechatCode = this.GetQueryString('code')
      if (!wechatCode) return
      let res = await this.http.service({
        url: `tdp/auth/portal/wechat/token?code=${wechatCode}&appId=wxbe194d218bda1c86`
      })
      if (res.code !== 200) {
        this.$message.warning(res.message)
        return
      }
      const unionid = res.data.unionid
      if (!unionid) {
        return
      }
      const res2 = await this.http.service({
        url: `tdp/data/gateway/v2/UnionidSelect/select`,
        data: {
          unionid
        }
      })
      if (res2.code === 1000) {
        this.isRegister = false
        this.activeName = 'first'
        return
      }
      if (res2.code !== 200) {
        this.$message.warning(res2.message)
        return
      }
      //   未绑定
      if (res2.data.length === 0) {
        _this.bound = false
        _this.currentunionid = res.data.unionid
        // _this.$prompt('联系电话', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        // }).then(async ({ value }) => {
        //   if (!_this.checkTelephone(value)) {
        //     _this.$message.warning('联系电话格式不正确')
        //     return
        //   }
        //   const res = await _this.http.service({
        //     url: '/tdp/script/Invoke/bindingUnionid',
        //     data: {
        //       "params": { "mobile": value, unionid }
        //     }
        //   })
        //   if (res.code !== 200) {
        //     _this.$message.warning(res.message)
        //     return
        //   }
        //   _this.form.mobile = res.data.mobile
        //   localStorage.setItem('curphone', res.data.mobile)
        //   _this.selectstaus()
        // })
      } else {
        _this.bound = true
        _this.form.mobile = res2.data[0].mobile
        localStorage.setItem('curphone', res2.data[0].mobile)
        _this.selectstaus()
      }
    }
  },
  created () {
    this.handleCode()
  },
  mounted () {
    document.title = '申请试用'
  }
}
</script>

<style lang='scss' scoped>
::v-deep .el-form-item {
  margin-bottom: 0.2rem;
}

::v-deep .el-form--label-top .el-form-item__label {
  padding-bottom: 0;
  height: 34px;
}
::v-deep .el-input__inner{
  height: 48px;
  line-height: 480x;
}
::v-deep .el-button{
  padding: 18px 20px;
}
.ApplicationUse {
  width: 100vw;
  height: 100%;

  .confirm-btn {
    ::v-deep.el-form-item__content {
      margin-left: 0 !important;
    }
  }

  .header {
    height: 1.02rem;
    background-color: #0560D6;
  }

  .contion {
    display: flex;

    .right {
      width: 100%;
      padding: 0 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .title {
        margin-top: 1.2rem;
        width: 100%;
        text-align: center;
        height: 0.39rem;
        font-size: 0.28rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #0560D6;
        line-height: 0.33rem;
        font-size: .36rem;
        font-weight: 700;
      }

      .steps {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
        width: 94%;

        .line {
          width: 2.5rem;
          height: 2px;
          // margin: auto 0;
          background: #c0c4cc;
          border-radius: 0rem 0rem 0rem 0rem;
          opacity: 1;
          margin-bottom: 0.5rem;
        }

        .blueline {
          background-color: #0560d6;
        }

        .st {
          display: flex;
          width: 33.3333%;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .index {
            display: block;
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 50%;
            opacity: 1;
            border: 0.5px solid #c0c4cc;
            text-align: center;
            font-size: 0.3rem;
            line-height: 0.5rem;
            margin-bottom: 0.2rem;
            color: #C0C4CC;
          }

          .font {
            height: 0.32rem;
            font-size: 0.24rem;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            line-height: 0.32rem;
            color: #c0c4cc;
          }

          .blue {
            color: #0560d6;
          }
        }

        .blue {
          .index {
            color: #fff;
            background-color: #0560d6;

          }

          .font {
            color: #0560d6;
          }
        }

      }

      .form-l {
        /* width: 7rem; */
        /* margin: 0 auto; */
        width: 90%;
        padding-top: 0.84rem;

        .message-form-1 {
          /* width: 5rem; */
          /* margin-left: 0.8rem; */
        }
      }

      .message-form {
        text-align: center;
        display: flex;
        flex-direction: column;

        .iconcheck {
          width: 1.5rem;
          height: 1.5rem;
          opacity: 1;
          margin: 0 auto;
          margin-bottom: .2rem;

          img {
            background-size: 100%;
            width: 1.5rem;
          }
        }

        .span1 {
          margin-top: 1rem;
          height: .32rem;
          font-size: .4rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.85);
          line-height: .32rem;
        }

        .span2 {
          margin-top: 0.06rem;
          height: .4rem;
          font-size: .26rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.43);
          line-height: .4rem;
        }

        .message-form-2 {
          display: flex;
          justify-content: space-between;


          .message-form-2-left {
            flex: 1;
            text-align: left;

            .message-form-2-title {
              text-align: left;
              height: .28rem;
              font-size: .2rem;
              font-family: PingFang SC-Medium, PingFang SC;
              font-weight: 500;
              color: #191A24;
              line-height: .23rem;
              margin-bottom: .4rem;
            }

            .item-list {
              display: flex;
              flex-direction: column;

              .list {
                display: flex;
                margin-bottom: .18rem;

                .text {
                  height: 0.22rem;
                  font-size: 0.12rem;
                  font-family: PingFang SC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #616972;
                }
              }
            }

            .text {
              height: .21rem;
              font-size: .12rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #C0C4CC;
              line-height: .21rem;
            }

            .btn {
              margin-top: .2rem;
            }
          }

          .message-form-2-right {
            .message-form-2-right-container {
              margin-top: .2rem;

              .text1 {
                height: .22rem;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #565E66;
                line-height: .22rem;
              }

              .text2 {
                height: .22rem;
                font-size: .14rem;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #565E66;
                line-height: .22rem;
              }
            }
          }
        }
      }
    }

    .no-register-right {
      margin: 2rem auto 0;

      .title {
        height: 39px;
        font-size: 28px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #191A24;
        line-height: .33rem;
        margin-bottom: .6rem;
      }

      .tab-container {

        ::v-deep .el-tab-pane {
          padding-top: .5rem;
        }

        ::v-deep .el-tabs__item {
          padding: 0 .3rem;
        }
      }

      ::v-deep .el-form-item {
        display: flex;
        flex-direction: column;
        /* margin-bottom: 0.15rem; */

        .confirm-btn {
          color: #FFFFFF;
          height: .4rem;
          background: #0560D6;
          border-radius: .04rem;
          opacity: 1;
          text-align: center;
          line-height: .4rem;
          cursor: pointer;
        }

        .message-box {
          display: flex;

          .btn {
            min-width: 1.42rem;
            max-width: 1.42rem;
            height: .47rem;
            background: #FFFFFF;
            border-radius: .04rem;
            opacity: 1;
            border: .01rem solid #0560D6;
            cursor: pointer;
            margin: auto;
            // margin-left: .06rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .text {
              width: 0.9rem;
              height: .21rem;
              font-size: .12rem;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #0560D6;
              line-height: .21rem;
              text-align: center;

              &.time {
                /* color: orange; */
                font-size: .2rem;
              }
            }
          }

        }

        .el-form-item__label {
          height: .21rem;
          font-size: .16rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #616972;
          line-height: .21rem;
          margin-bottom: 0.06rem;
        }

        .el-form-item__content {
          margin-left: 0 !important;

          .el-input__inner {
            height: 0.47rem;
          }

          .el-select {
            width: 100%;
          }
        }
      }

      .wechat-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .text1 {
          height: .2rem;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #565E66;
          line-height: .2rem;
          float: left;
        }

        .text2 {
          height: .2rem;
          font-size: .14rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #191A24;
          line-height: .2rem;
          float: right;
          cursor: pointer;
        }
      }
    }
  }
}

.blue {
  .index {
    // background-color: #0560D6 !important;
  }

  .font {
    // color: #303133 !important;
  }

}
</style>
